import { render, staticRenderFns } from "./HomepageBestSellerFirst.vue?vue&type=template&id=7503434a&scoped=true"
import script from "./HomepageBestSellerFirst.vue?vue&type=script&lang=js"
export * from "./HomepageBestSellerFirst.vue?vue&type=script&lang=js"
import style0 from "./HomepageBestSellerFirst.vue?vue&type=style&index=0&id=7503434a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7503434a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/opt/atlassian/pipelines/agent/build/components/Icon.vue').default,CollectionProductItemSkeleton: require('/opt/atlassian/pipelines/agent/build/containers/collection/CollectionProductItemSkeleton.vue').default})
